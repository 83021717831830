var app = angular.module('onimuna', []);

app.controller('contratarCtrl', function ($http, $sce, $window)
{
    var vm = this;
    vm.planos = [];
    vm.planoSelecionado = 0;
    vm.contratacao = {};
    vm.plano = {};
    vm.periodoSelecionado = 'mensal';
    vm.liEConcordo = false;
    vm.enviando = false;
    vm.qtdeUsuariosPretende = 1;
    // vm.cupom = null;

    vm.escolherPlano = function(qtdeUsuarios)
    {
        for (var i = 0; i < vm.planos.length; i++)
        {
            if (vm.planos[i].minimo_usuarios <= qtdeUsuarios)
            {
                vm.plano = vm.planos[i];
            }
        } 
    }

    vm.carregarPlanos = function ()
    {
        $http.post('/site/planos').then(
            function successCallback(resposta)
            {
                vm.planos = resposta.data.planos;

                if (vm.planoSelecionado > 0)
                {
                    for (var i = 0; i < vm.planos.length; i++)
                    {
                        if (vm.planos[i].codigo == vm.planoSelecionado)
                        {
                            vm.plano = vm.planos[i];
                            vm.qtdeUsuariosPretende = vm.planos[i].minimo_usuarios;

                            break;
                        }
                    }
                }

                vm.escolherPlano(vm.qtdeUsuariosPretende);

            },
            function errorCallback(response)
            {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
            }
        );
    };

    vm.interpretarMeses = function(qtdeMeses)
    {
        if (qtdeMeses == 12)
            return 'um ano';
        else
            return qtdeMeses + ' meses';

    };

    vm.calcularDesconto = function(valor)
    {
        var desconto = vm.cupom.porcentagem / 100;

        return valor - (valor * desconto);
    };

    vm.carregarCupom = function(referencia)
    {
        vm.erroCupom = '';
        vm.cupom = null;
        vm.parceiro = null;

        if (referencia != '')
        {
            $http.post('/site/carregar-cupom/' + referencia).then(
                function sucessCallback(response)
                {
                    vm.cupom = response.data.desconto;
                    vm.parceiro = response.data.parceiro;

                    if (vm.cupom == null)
                        vm.erroCupom = 'Cupom não encontrado.';
                },
                function errorCallback()
                {
                    vm.erroCupom = 'Cupom não encontrado.';
                }
            );
        }
    };

    vm.calcularEconomia = function()
    {
        var valorBase = vm.plano['valor_'+vm.periodoSelecionado];
        var valorBaseOriginal = vm.plano['valor_'+vm.periodoSelecionado+'_original'];

        if (vm.periodoSelecionado == 'semestral')
        {
            valorBase = valorBase * 2;
            valorBaseOriginal = valorBaseOriginal * 2;
        }

        if (vm.periodoSelecionado == 'mensal')
        {
            valorBase = valorBase * 12;
            valorBaseOriginal = valorBaseOriginal * 12;

        }

        var valorCalculado = (vm.plano.valor_mensal * 12) - (valorBase);

        if (vm.cupom)
        {
            valorCalculado = valorCalculado + (valorBase * (vm.cupom.porcentagem / 100));
        }

        if (valorBaseOriginal > 0)
            valorCalculado = valorCalculado + (parseFloat(valorBaseOriginal) - parseFloat(valorBase));

        return valorCalculado;
    }


    vm.contratar = function ($event)
    {
        $event.stopPropagation();
        $event.preventDefault();
        if (vm.liEConcordo)
        {

            if (vm.enviando == false)
            {
                vm.enviando = true;
                vm.erroEmail = null;
                vm.erroNome = null;
                vm.erroReferencia = null;

                vm.contratacao.plano = vm.planoSelecionado;
                vm.contratacao.tipo = vm.periodoSelecionado.substr(0, 1).toUpperCase();
                if (vm.cupom)
                    vm.contratacao.cupom = vm.cupom.referencia;
                else
                    vm.contratacao.cupom = null



                $http.post('/site/contratar', vm.contratacao).then(
                    function successCallback(resposta)
                    {

                        $window.location.href = resposta.data.url;

                    },
                    function errorCallback(response)
                    {
                        vm.enviando = false;
                        var erros = response.data.errors;

                        if (erros['email'])
                        {
                            vm.erroEmail = erros['email'][0];
                        }
                        if (erros['nome'])
                        {
                            vm.erroNome = erros['nome'][0];
                        }
                        if (erros['referencia'])
                        {
                            vm.erroReferencia = erros['referencia'][0];
                        }
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                    }
                );

            }
        }
        else
        {
            alert('É necessário aceitar os termos de uso!');
        }

    };


    vm.lerHtml = function (snippet)
    {
        return $sce.trustAsHtml(snippet);
    };

    vm.carregarPlanos();


});


app.controller('upgradeAssinaturaCtrl', function ($http, $sce, $window)
{
    var vm = this;
    vm.planos = [];
    vm.planoSelecionado = 0;
    vm.contratacao = {};
    vm.plano = {};
    vm.periodoSelecionado = 'mensal';
    vm.confirmacaoUpgrade = false;
    vm.enviando = false;
    // vm.cupom = null;

    vm.carregarPlanos = function ()
    {
        $http.post('/planos').then(
            function successCallback(resposta)
            {
                vm.planos = resposta.data.planos;
                vm.nossoCliente = resposta.data.planos;
                vm.parceiro = resposta.data.parceiro;
                vm.valorDescontoPlanoAtual = resposta.data.valorDescontoPlanoAtual;
                vm.cupom = resposta.data.desconto;

                if (vm.planoSelecionado > 0)
                {
                    for (var i = 0; i < vm.planos.length; i++)
                    {
                        if (vm.planos[i].codigo == vm.planoSelecionado)
                        {
                            vm.plano = vm.planos[i];
                            break;
                        }
                    }
                }

            },
            function errorCallback(response)
            {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
            }
        );
    };

    vm.interpretarMeses = function(qtdeMeses)
    {
        if (qtdeMeses == 12)
            return 'um ano';
        else
            return qtdeMeses + ' meses';

    };

    vm.calcularDesconto = function(valor)
    {
        var desconto = vm.cupom.porcentagem / 100;

        return valor - (valor * desconto);
    };

    

    vm.calcularDescontoUpgrade = function(valor)
    {
        return valor - vm.valorDescontoPlanoAtual;
    };

    vm.selecionarPlano = function (plano)
    {
        vm.planoSelecionado == plano.codigo ? vm.planoSelecionado = 0 : vm.planoSelecionado = plano.codigo;

        vm.plano = plano;
    };

    vm.confirmarUpgrade = function ($event)
    {
        $event.preventDefault();
        if (vm.confirmacaoUpgrade)
        {

            if (vm.enviando == false)
            {
                vm.enviando = true;
                vm.erroEmail = null;
                vm.erroNome = null;
                vm.erroReferencia = null;

                vm.contratacao.plano = vm.planoSelecionado;
                vm.contratacao.tipo = vm.periodoSelecionado.substr(0, 1).toUpperCase();


                $http.post('/confirmar-upgrade', vm.contratacao).then(
                    function successCallback(resposta)
                    {

                        $window.location.href = resposta.data;

                    },
                    function errorCallback(response)
                    {
                        vm.enviando = false;
                        var erros = response.data.errors;

                        if (erros['email'])
                        {
                            vm.erroEmail = erros['email'][0];
                        }
                        if (erros['nome'])
                        {
                            vm.erroNome = erros['nome'][0];
                        }
                        if (erros['referencia'])
                        {
                            vm.erroReferencia = erros['referencia'][0];
                        }
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                    }
                );

            }
        }
        else
        {
            alert('É necessário checar a opção de confirmação acima do botão!');
        }

    };


    vm.lerHtml = function (snippet)
    {
        return $sce.trustAsHtml(snippet);
    };

    vm.carregarPlanos();


});


app.controller('assinaturaCtrl', function ($http, $sce, $window)
{
    var vm = this;
    vm.carregando = false;
    vm.planos = [];
    vm.planoSelecionado = 0;
    vm.contratacao = {};
    vm.plano = {};
    vm.assinatura = {};
    vm.periodoSelecionado = 'mensal';
    vm.concluido = false;

    vm.assinar = function($event)
    {
        if (vm.carregando)
        {
            $event.preventDefault();

            return false;
        }
        else
        {
            vm.carregando = true;
            vm.concluido = true;
        }

    };
    vm.carregarInformacoes = function()
    {
        $http.post('/informacoes-assinatura').then(
            function successCallback(resposta)
            {
                vm.planos = resposta.data.planos;

                vm.planoSelecionado = resposta.data.assinatura.plano;

                vm.assinatura = resposta.data.assinatura;
                vm.plano =  resposta.data.assinatura.plano_r;
                vm.proximoPlano =  resposta.data.proximo_plano;
                vm.cupom = resposta.data.desconto;
                vm.parceiro = resposta.data.parceiro;

                if (resposta.data.assinatura.tipo == 'M')
                    vm.periodoSelecionado = 'mensal';

                if (resposta.data.assinatura.tipo == 'S')
                    vm.periodoSelecionado = 'semestral';

                if (resposta.data.assinatura.tipo == 'A')
                    vm.periodoSelecionado = 'anual';

                vm.servidor = resposta.data;
            },
            function errorCallback(response)
            {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
            }
        );
    };

    vm.selecionarPlano = function (plano)
    {
        vm.planoSelecionado == plano.codigo ? vm.planoSelecionado = 0 : vm.planoSelecionado = plano.codigo;

        vm.plano = plano;
    };

    vm.interpretarMeses = function(qtdeMeses)
    {
        if (qtdeMeses == 12)
            return 'um ano';
        else
            return qtdeMeses + ' meses';

    };

    vm.calcularDesconto = function(valor)
    {
        var desconto = vm.cupom.porcentagem / 100;

        return valor - (valor * desconto);
    };

    vm.calcularEconomia = function()
    {
        var valorBase = vm.plano['valor_'+vm.periodoSelecionado];

        if (vm.periodoSelecionado == 'semestral')
            valorBase = valorBase * 2;

        if (vm.periodoSelecionado == 'mensal')
            valorBase = valorBase * 12;

        var valorCalculado = (vm.plano.valor_mensal * 12) - (valorBase);

        if (vm.cupom)
        {
            valorCalculado = valorCalculado + (valorBase * (vm.cupom.porcentagem / 100));
        }

        return valorCalculado;
    }

    vm.lerHtml = function (snippet)
    {
        return $sce.trustAsHtml(snippet);
    };

    vm.carregarInformacoes();


});

app.controller('instalarCtrl', function ($http, $sce, $window)
{
    var vm = this;
    vm.enviando = false;
    vm.erros = [];
    vm.usuario = {sexo: 'M'};

    vm.instalar = function ($event)
    {
        $event.preventDefault();
        if (vm.enviando == false)
        {
            vm.enviando = true;

            vm.erros = [];

            $http.post('/instalar', vm.usuario).then(
                function successCallback(resposta)
                {

                    $window.location.href = resposta.data.url;

                },
                function errorCallback(response)
                {
                    vm.enviando = false;
                    vm.erros = response.data.errors;
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                }
            );

        }

    };


});

app.controller('acessarCtrl', function($window) {
    var vm = this;
    vm.referencia = '';

    vm.acessarSistema = function($event)
    {
        $event.preventDefault();

        if (vm.referencia)
            $window.location.href = 'http://' + vm.referencia + '.onimuna.com.br';

    };
});

app.controller('feedbackCtrl', function($window, $http) {
    var vm = this;

    vm.feedback = '';
    vm.enviado = false;

    vm.enviarFeedback = function($event)
    {
        $event.preventDefault();

        if (vm.feedback != '' && vm.feedback != null)
        {
            vm.enviado = true;

            $http.post('/site/enviar-feedback', {email: vm.email, feedback: vm.feedback}).then(
                function successCallback(resposta)
                {
                },
                function errorCallback(resposta)
                {

                }
            )
        }

    };
});

app.controller('contatoCtrl', function($window, $http) {
    var vm = this;

    vm.data = {
        email: '',
        nome: '',
        mensagem: '',
        verificacao: '',
    };
    vm.enviado = false;

    vm.enviarContato = function($event)
    {
        $event.stopPropagation();
        $event.preventDefault();

        if (!vm.data.email
            || !vm.data.nome
            || !vm.data.mensagem
            || !vm.data.verificacao)
        {
            alert('Campos com asterisco são obrigatórios!');
            
            return;
        }

        if (vm.data.email.substr(0,1) != vm.data.verificacao)
        {
            alert('Verificação incorreta!');
            
            return;
        }

        vm.enviado = true;

        $http.post('/site/enviar-contato', vm.data).then(
            function successCallback(resposta)
            {
                alert(resposta.data);
            },
            function errorCallback(resposta)
            {
                alert('Erro ao enviar o email, por favor tente novamente mais tarde!');

            }
        );

    };
});

app.controller('assinaturaPagSeguroCtrl', function($http, $window, $scope){

    var vm = this;
    vm.errosPagSeguro = null;
    vm.carregando = false;
    vm.planos = [];
    vm.planoSelecionado = 0 ;
    vm.contratacao = {};
    vm.plano = {};
    vm.assinatura = {};
    vm.periodoSelecionado = 'mensal';
    vm.concluido = false;
    vm.etapa = 1;
    vm.cartao = {};
    vm.dadosPagamento = {
        tipo_documento: 'cpf'
    };
    vm.cartao = {
        // numero: '4111111111111111',
        bandeira: {
            name: 'visa',
        },
        // cvv: '013',
        // mes_expiracao: '12',
        // ano_expiracao: '2026'
    };

    vm.assinar = function($event)
    {
        if (vm.carregando)
        {
            $event.preventDefault();

            return false;
        }
         else
        {
            vm.carregando = true;
            vm.concluido = true;
        }

    };

    vm.carregarInformacoes = function()
    {
        $http.post('/informacoes-assinatura').then(
            function successCallback(resposta)
            {
                vm.planos = resposta.data.planos;

                vm.planoSelecionado = resposta.data.assinatura.plano;

                vm.assinatura = resposta.data.assinatura;
                vm.plano =  resposta.data.assinatura.plano_r;
                vm.proximoPlano =  resposta.data.proximo_plano;
                vm.cupom = resposta.data.desconto;
                vm.parceiro = resposta.data.parceiro;

                if (resposta.data.assinatura.tipo == 'M')
                    vm.periodoSelecionado = 'mensal';

                if (resposta.data.assinatura.tipo == 'S')
                    vm.periodoSelecionado = 'semestral';

                if (resposta.data.assinatura.tipo == 'A')
                    vm.periodoSelecionado = 'anual';

                vm.servidor = resposta.data;

            },
            function errorCallback(response)
            {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
            }
        );
    };

    vm.cancelarAssinatura = function()
    {
        $http.post('pagseguro/cancelar-assinatura', {confirmacao: 'sim'}).then(function successCallback(){
            $window.location.reload();

        });
    }

    vm.selecionarPlano = function (plano)
    {
        vm.planoSelecionado == plano.codigo ? vm.planoSelecionado = 0 : vm.planoSelecionado = plano.codigo;

        vm.plano = plano;
    };

    vm.interpretarMeses = function(qtdeMeses)
    {
        if (qtdeMeses == 12)
            return 'um ano';
        else
            return qtdeMeses + ' meses';

    };

    vm.calcularDesconto = function(valor)
    {
        var desconto = vm.cupom.porcentagem / 100;

        return valor - (valor * desconto);
    };

    vm.calcularEconomia = function()
    {
        var valorBase = vm.plano['valor_'+vm.periodoSelecionado];

        if (vm.periodoSelecionado == 'semestral')
            valorBase = valorBase * 2;

        if (vm.periodoSelecionado == 'mensal')
            valorBase = valorBase * 12;

        var valorCalculado = (vm.plano.valor_mensal * 12) - (valorBase);

        if (vm.cupom)
        {
            valorCalculado = valorCalculado + (valorBase * (vm.cupom.porcentagem / 100));
        }

        return valorCalculado;
    }

    vm.lerHtml = function (snippet)
    {
        return $sce.trustAsHtml(snippet);
    };

    vm.carregarInformacoes() ;

    vm.carregarBandeira = function()
    {
        if (vm.cartao.numero.length >= 6)
        {
            PagSeguroDirectPayment.getBrand({
                cardBin: vm.cartao.numero.substring(0,6),
                success: function(response) {
                    //bandeira encontrada

                    vm.cartao.bandeira = response.brand;
                    $scope.$apply();
                },
                error: function(response) {
                    //tratamento do erro
                },
                complete: function(response) {
                    //tratamento comum para todas chamadas
                }
            });
        }

    }

    vm.traduzirErroPagSeguro = function(codigoErro, mensagemErro)
    {
        if (codigoErro == '10027')
            return 'Telefone é obrigatório';
        if (codigoErro == '10028')
            return 'DDD é obrigatório';
        if (codigoErro == '11013')
            return 'DDD inválido';
        if (codigoErro == '10049')
            return 'Nome é obrigatório';
        if (codigoErro == '10050')
            return 'Email é obrigatório';
        if (codigoErro == '50103')
            return 'CEP é obrigatório';
        if (codigoErro == '50105')
            return 'Número é obrigatório';
        if (codigoErro == '50106')
            return 'Bairro é obrigatório';
        if (codigoErro == '50108')
            return 'Cidade é obrigatório';
        if (codigoErro == '50134')
            return 'Endereço é obrigatório';
        if (codigoErro == '50047')
            return 'Data de nascimento é obrigatório';
        if (codigoErro == '50048')
            return 'Data de nascimento inválida, formato correto: DD/MM/AAAAA';
        if (codigoErro == '50038')
            return 'Estado é obrigatório';
        if (codigoErro == '53042')
            return 'Nome impresso no cartão de crédito é obrigatório';
        if (codigoErro == '53047')
            return 'Data de nascimento é obrigatório';
        if (codigoErro == '53048')
            return 'Data de nascimento inválida, formato correto: DD/MM/AAAAA';
        if (codigoErro == '57038')
            return 'Estado é obrigatório';
        if (codigoErro == '19007')
            return 'Estado inválido';
        if (codigoErro == '61010')
            return 'CPF inválido (digite apenas números)';
        if (codigoErro == '61011')
            return 'CPF inválido (digite apenas números)';
        if (codigoErro == '19001')
            return 'CEP inválido';
        if (codigoErro == '61009')
            return 'CPF é obrigatório';
        if (codigoErro == '10001')
            return 'Quantidade de digitos do número do cartão de crédito é inválido';
        if (codigoErro == '10002')
            return 'Expiração inválida de cartão de crédito';
        if (codigoErro == '30405')
            return 'Expiração inválida de cartão de crédito';
        if (codigoErro == '10004')
            return 'CVV do cartão de crédito é obrigatório';
        if (codigoErro == '10006')
            return 'CVV do cartão de crédito com quantidade de digitos inválida';


        return codigoErro + ' || ' + mensagemErro;
    };

    vm.aderirAoPlano = function()
    {
        if (!vm.enviandoAssinatura)
        {
            vm.enviandoAssinatura = true;
            vm.errosPagSeguro = null;
            PagSeguroDirectPayment.createCardToken({
                cardNumber: vm.cartao.numero, // Número do cartão de crédito
                brand: vm.cartao.bandeira.name,// Bandeira do cartão
                cvv: vm.cartao.cvv, // CVV do cartão
                expirationMonth: vm.cartao.mes_expiracao, // Mês da expiração do cartão
                expirationYear: vm.cartao.ano_expiracao, // Ano da expiração do cartão, é necessário os 4 dígitos.
                success: function(response) {
                    vm.dadosPagamento.token = response.card.token;

                    $http
                        .post('pagseguro/registrar-assinatura', vm.dadosPagamento)
                        .then(
                            function success(response) {
                                // $window.location.reload();
                                // vm.enviandoAssinatura = false;

                                vm.errosPagSeguro = null;


                                if (response.data.error == true)
                                {
									vm.errosPagSeguro = response.data.errors;
									vm.enviandoAssinatura = false;
                                }
                                else
                                    $window.location.reload();

                            },
                            function error(response) {

                            }
                        );
                },
                error: function(response) {
                    // Callback para chamadas que falharam.

                    vm.errosPagSeguro = response.errors;
                    vm.enviandoAssinatura = false;
                    $scope.$apply();

                },
                complete: function(response) {
                    // Callback para todas chamadas.


                }
            });
        }

    }

});